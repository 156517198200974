import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import Results from "./components/dashboard/Results";

// Redux
import store from "./redux/store";
import { Provider } from "react-redux";

// Routes
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LogIn from "./components/auth/LogIn";
import Home from "./components/home/Home";
import PostWrapper from "./components/results/PostWrapper";
import Post from "./components/results/Post";
import Signup from "./components/auth/Signup";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path={"/"} element={<App />} />
          <Route path={"results"} element={<Results />} />
          <Route path={"login"} element={<LogIn />} />
          <Route path={"signup"} element={<Signup />} />
          <Route path={"home"} element={<Home />} />
          <Route path={"posts"} element={<PostWrapper />}>
            <Route path={":postId"} element={<Post />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);
