import { setNouns } from "../../../redux/results/nounSlice";
import { setNounCount } from "../../../redux/results/nounCountSlice";
import { setVerbs } from "../../../redux/results/verbSlice";
import { setVerbCount } from "../../../redux/results/verbCountSlice";
import {
  setCity,
  setCompany,
  setEmploymentType,
  setIndustry,
  setJobFunction,
  setTitle,
} from "../../../redux/results/overviewSlice";
import { setKeywords } from "../../../redux/results/keywords";
import { Dispatch } from "@reduxjs/toolkit";
import { AnalyzedPost } from "../../../types/analyzedpost";

/**
 * set analyzed data to redux
 * run on click
 * @param data response obj from backend either from Firebase or API
 * @param {Dispatch} dispatch redux
 */
const parseFormData = (data: AnalyzedPost, dispatch: Dispatch) => {
  dispatch(setNouns(data.nouns));
  dispatch(setNounCount(data.nounCount.map(Number)));
  dispatch(setVerbs(data.verbs));
  dispatch(setVerbCount(data.verbCount.map(Number)));
  dispatch(setTitle(data.title));
  dispatch(setCity(data.city));
  dispatch(setCompany(data.company));
  dispatch(setEmploymentType(data.employmentType));
  dispatch(setIndustry(data.industries));
  dispatch(setJobFunction(data.jobFunction));
  dispatch(setKeywords(data.keywords));
};

export default parseFormData;
