import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarChartComponent = (props: any) => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const data = {
    labels: props.label,
    // backgroundColor: 'rgb(241 245 249)',
    datasets: [
      {
        data: props.data,
        backgroundColor: ["rgb(99,102,241)", "rgb(95,165,250)"],
        borderColor: ["rgba(99,102,241,0.2)", "rgba(95,165,250, 0.2)"],
        borderWidth: 1,
        hoverOffset: 4,
        barPercentage: 0.66,
        categoryPercentage: 0.66,
      },
    ],
  };

  return (
    <div className="flex-auto bg-slate-50">
      <h2 className={"text-3xl text-center font-semibold"}>{props.title}</h2>
      <div className={"px-5 pt-2 pb-2"}>
        <Bar data={data} options={options} />
      </div>
    </div>
  );
};

export default BarChartComponent;
