import NavBar from "../home/NavBar";
import { Outlet } from "react-router-dom";

const PostWrapper = () => {
  return (
    <>
      <div className="min-h-full">
        <NavBar />
        <main className={"min-h-full"}>
          <Outlet />
        </main>
      </div>
    </>
  );
};

export default PostWrapper;
