import { arrayUnion, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { ISavedPost, UserModel } from "../types/user";

/**
 * get user document
 * @param db
 * @param userId - user.uid
 */
export const getUserDoc = async (db: any, userId: string) => {
  const userRef = doc(db, "users", userId);
  const user = await getDoc(userRef);
  return user.data();
};

/** TODO: has to be a better way
 * gets user document and checks if user has already searched for it
 * @param db
 * @param postId
 * @param userId
 */
export const userDocHasPost = async (
  db: any,
  postId: string,
  userId: string
): Promise<boolean> => {
  const userRef = doc(db, "users", userId);
  const docSnap = await getDoc(userRef);
  if (docSnap.exists()) {
    const savedPosts = docSnap.data().savedPosts;
    for (let savedPostId of savedPosts) {
      if (savedPostId === postId) return true;
    }
  }
  return false;
};

/**
 * create new user Document
 * @param db
 * @param uid - Returned by firebase auth on account creation
 * @param data - UserModel {name, email, savedPosts}
 */
export const createNewUser = async (db: any, uid: string, data: UserModel) => {
  await setDoc(doc(db, "users", uid), data);
};

/**
 * updates user document to include new postId
 * @param db
 * @param postId
 * @param userId - user.uid
 */
export const addPostIdToUserDoc = async (
  db: any,
  postId: string,
  userId: string
) => {
  const userRef = doc(db, "users", userId);
  await updateDoc(userRef, { savedPosts: arrayUnion(postId) });
};

/** TODO: Update to add SavedPost {postId, title, company}
 * updates user document to include new postId
 * @param db
 * @param post postId, title, company
 * @param userId - user.uid
 */
export const addSavedPost = async (
  db: any,
  post: ISavedPost,
  userId: string
) => {
  const userRef = doc(db, "users", userId);
  await updateDoc(userRef, { savedPosts: arrayUnion(post) });
};

export const isUserInDatabase = async (db: any, userId: any) => {
  const userRef = doc(db, "users", userId);
  const docSnap = await getDoc(userRef);
  return docSnap.exists();
};
