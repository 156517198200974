import NavBar from "./NavBar";
import AnalyzePostsForm from "./AnalyzePostsForm";
import SavedPost from "./SavedPost";
import { auth } from "../../firebase";
import { ISavedPost } from "../../types/user";
import { getUserAuth } from "../../api/auth";
import firebase from "firebase/compat";
import { useGetListOfSavedPostsQuery } from "../../redux/firebase/firebaseSlice";

export default function Home() {
  // userId
  const userAuth: firebase.User = getUserAuth(auth);
  const { data, error, isLoading } = useGetListOfSavedPostsQuery(userAuth.uid);

  const handlePosts = (
    data: ISavedPost[] | undefined
  ): JSX.Element[] | JSX.Element => {
    if (data) {
      console.log(data);
      return data.map((post: ISavedPost) => (
        <SavedPost
          postId={post.postId}
          title={post.title}
          company={post.company}
        />
      ));
    } else {
      return (
        <div className={"my-60"}>
          <h3>No posts!</h3>
        </div>
      );
    }
  };

  return (
    <>
      <div className="min-h-full">
        <NavBar />
        <main className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          {/* CONTENT - START */}
          <AnalyzePostsForm />
          <h2 className={"text-3xl mt-3 font-bold text-gray-900 text-center"}>
            Saved Posts
          </h2>
          <div className={"flex justify-evenly flex-wrap"}>
            {!isLoading && !error && handlePosts(data)}
          </div>
          {/* CONTENT - END */}
        </main>
      </div>
    </>
  );
}
