import { createSlice } from "@reduxjs/toolkit";

// Define a type for the slice state
interface OverviewState {
  value: any[];
}

// Define the initial state using that type
const initialState: OverviewState = {
  value: [],
};

export const overviewSlice = createSlice({
  name: "overview",
  initialState,
  reducers: {
    setTitle(state, action) {
      state.value.push({ title: action.payload });
    },
    setCity(state, action) {
      state.value.push({ city: action.payload });
    },
    setCompany(state, action) {
      state.value.push({ company: action.payload });
    },
    setEmploymentType(state, action) {
      state.value.push({ employmentType: action.payload });
    },
    setIndustry(state, action) {
      state.value.push({ industry: action.payload });
    },
    setJobFunction(state, action) {
      state.value.push({ jobFunction: action.payload });
    },
  },
});

export const {
  setTitle,
  setCity,
  setCompany,
  setJobFunction,
  setIndustry,
  setEmploymentType,
} = overviewSlice.actions;

export default overviewSlice.reducer;
