import { createSlice } from "@reduxjs/toolkit";

export const nounSlice = createSlice({
  name: "verb",
  initialState: {
    value: [],
  },
  reducers: {
    setVerbs: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setVerbs } = nounSlice.actions;

export default nounSlice.reducer;
