const LoadImg = require("../../assets/gifs/flame-searching.gif");

const LoadScreen = () => {
  return (
    <>
      <div
        className={
          "min-h-full min-w-full fixed inset-0 z-50 flex justify-center items-center bg-neutral bg-opacity-30 backdrop-blur"
        }
      >
        <img className={"h-fit w-fit"} src={LoadImg} alt={"loading"} />
      </div>
    </>
  );
};

export default LoadScreen;
