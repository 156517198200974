import { createSlice } from "@reduxjs/toolkit";

export const nounSlice = createSlice({
  name: "nounCount",
  initialState: {
    value: [],
  },
  reducers: {
    setNounCount: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setNounCount } = nounSlice.actions;

export default nounSlice.reducer;
