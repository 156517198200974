// https://tailwindui.com/components/application-ui/headings/page-headings
// import { Fragment } from 'react';
import {
  BriefcaseIcon,
  // CheckIcon,
  // ChevronDownIcon,
  LocationMarkerIcon,
  HomeIcon,
  OfficeBuildingIcon,
  TagIcon,
  HashtagIcon,
} from "@heroicons/react/solid";
// import { Menu, Transition } from '@headlessui/react';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setFalse } from "../../redux/loading/loadingSlice";

// function classNames(...classes: any) {
//   return classes.filter(Boolean).join(' ');
// }

interface IDashboardHeader {
  title: string;
  company: string;
  city: string;
  employmentType: string;
  industry: string;
  jobFunction: string;
}

const DashboardHeader = (props: IDashboardHeader) => {
  const {
    title,
    company,
    city,
    employmentType,
    jobFunction,
    industry,
  }: IDashboardHeader = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <div className="lg:flex lg:items-center lg:justify-between max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
      <div className="flex-1 min-w-0">
        <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
          {title}
        </h2>
        <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
          <div className="mt-2 flex items-center text-sm text-gray-500">
            <BriefcaseIcon
              className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            {employmentType}
          </div>
          <div className="mt-2 flex items-center text-sm text-gray-500">
            <OfficeBuildingIcon
              className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            {company}
          </div>
          <div className="mt-2 flex items-center text-sm text-gray-500">
            <LocationMarkerIcon
              className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            {city}
          </div>
          <div className="mt-2 flex items-center text-sm text-gray-500">
            <TagIcon
              className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            {jobFunction}
          </div>
          <div className="mt-2 flex items-center text-sm text-gray-500">
            <HashtagIcon
              className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            {industry}
          </div>
        </div>
      </div>
      <div className="mt-5 flex lg:mt-0 lg:ml-4">
        <span className="hidden sm:block">
          <button
            type="button"
            onClick={() => {
              dispatch(setFalse());
              navigate("/");
            }}
            className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <HomeIcon
              className="-ml-1 mr-2 h-5 w-5 text-gray-500"
              aria-hidden="true"
            />
            Home
          </button>
        </span>

        {/*<span className='sm:ml-3'>*/}
        {/*  <button*/}
        {/*    type='button'*/}
        {/*    className='inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'*/}
        {/*  >*/}
        {/*    <CheckIcon className='-ml-1 mr-2 h-5 w-5' aria-hidden='true' />*/}
        {/*    Save*/}
        {/*  </button>*/}
        {/*</span>*/}

        {/* Dropdown */}
        {/*<Menu as='div' className='ml-3 relative sm:hidden'>*/}
        {/*  <Menu.Button*/}
        {/*    className='inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'>*/}
        {/*    More*/}
        {/*    <ChevronDownIcon className='-mr-1 ml-2 h-5 w-5 text-gray-500'*/}
        {/*                     aria-hidden='true' />*/}
        {/*  </Menu.Button>*/}

        {/*  <Transition*/}
        {/*    as={Fragment}*/}
        {/*    enter='transition ease-out duration-200'*/}
        {/*    enterFrom='transform opacity-0 scale-95'*/}
        {/*    enterTo='transform opacity-100 scale-100'*/}
        {/*    leave='transition ease-in duration-75'*/}
        {/*    leaveFrom='transform opacity-100 scale-100'*/}
        {/*    leaveTo='transform opacity-0 scale-95'*/}
        {/*  >*/}
        {/*    <Menu.Items*/}
        {/*      className='origin-top-right absolute right-0 mt-2 -mr-1 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'>*/}
        {/*      <Menu.Item>*/}
        {/*        {({ active }) => (*/}
        {/*          <a*/}
        {/*            href='#/'*/}
        {/*            className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}*/}
        {/*          >*/}
        {/*            Edit*/}
        {/*          </a>*/}
        {/*        )}*/}
        {/*      </Menu.Item>*/}
        {/*      <Menu.Item>*/}
        {/*        {({ active }) => (*/}
        {/*          <a*/}
        {/*            href='#/'*/}
        {/*            className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}*/}
        {/*          >*/}
        {/*            View*/}
        {/*          </a>*/}
        {/*        )}*/}
        {/*      </Menu.Item>*/}
        {/*    </Menu.Items>*/}
        {/*  </Transition>*/}
        {/*</Menu>*/}
      </div>
    </div>
  );
};

export default DashboardHeader;
