import { createSlice } from "@reduxjs/toolkit";

export const keywordsSlice = createSlice({
  name: "keyword",
  initialState: {
    value: [],
  },
  reducers: {
    setKeywords: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setKeywords } = keywordsSlice.actions;

export default keywordsSlice.reducer;
