import LPNavBar from "./LPNavBar";
import HeroProduct from "./1-hero/HeroProduct";
// import Pricing from "./5-pricing/Pricing";
// import WhySection from "./2-Why/WhySection";
// import Feature from "./3-Feature/Feature";
import Footer from "./4-Footer/Footer";

const LandingPage = () => {
  return (
    <div>
      <LPNavBar />
      <HeroProduct />
      {/*<WhySection />*/}
      {/*<Feature />*/}
      {/*<Pricing />*/}
      <Footer />
    </div>
  );
};

export default LandingPage;
