import { useParams } from "react-router-dom";
import { useGetPostViaIdQuery } from "../../redux/firebase/firebaseSlice";
import DashboardHeader from "../dashboard/DashboardHeader";
import { AnalyzedPost } from "../../types/analyzedpost";
import BarChartComponent from "../dashboard/BarChartComponent";
import KeywordChart from "../dashboard/KeywordChart";
import DoughnutComponent from "../dashboard/DoughnutComponent";
import React from "react";

const Post = () => {
  let params = useParams();
  const { data, isLoading } = useGetPostViaIdQuery(params.postId);

  const showData = (data: any) => {
    const post = data.data() as AnalyzedPost;
    console.log("data = ", data);

    const allWords = post.nouns.concat(post.verbs);
    const allWordCounts = post.nounCount.concat(post.verbCount);

    const makeObjectFromData = (words: any, nums: any) => {
      let wordArr = [];
      for (let i = 0; i < words.length; i++) {
        wordArr.push({ words: words[i], count: nums[i] });
      }
      return wordArr;
    };

    const allObjects = makeObjectFromData(allWords, allWordCounts);
    allObjects.sort((a, b) => b.count - a.count);

    const orderedWords = [];
    const orderedWordCount = [];

    for (let item of allObjects) {
      orderedWords.push(item.words);
      orderedWordCount.push(item.count);
    }

    return (
      <>
        <div className={"p-8"}>
          <DashboardHeader
            title={post.title}
            company={post.company}
            city={post.city}
            employmentType={post.employmentType}
            industry={post.industries}
            jobFunction={post.jobFunction}
          />
        </div>
        <div
          className={
            "flex flex-col max-h-screen m-auto lg:flex-row max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 "
          }
        >
          <div
            className={
              "w-auto h-fit lg:w-2/3 shadow-lg rounded-sm border border-slate-200"
            }
          >
            <BarChartComponent
              title={"Top 20 Words"}
              label={orderedWords}
              data={orderedWordCount}
            />
          </div>
          <div className={"px-4"} />
          <div
            className={
              "w-auto h-fit lg:w-fit shadow-lg rounded-sm border border-slate-200"
            }
          >
            <KeywordChart keywords={post.keywords} />
          </div>
        </div>

        <div className={"py-4"} />

        <div
          id={"donut-container"}
          className={
            "flex flex-col lg:flex-row max-w-7xl m-auto px-2 sm:px-6 lg:px-8 justify-evenly"
          }
        >
          <div
            className={
              "flex-auto max-h-full max-w-md lg:w-auto lg:flex-1 flex flex-col  lg:mb-12 shadow-lg rounded-sm border border-slate-200"
            }
          >
            <DoughnutComponent
              title={"Nouns"}
              label={post.nouns}
              data={post.nounCount}
            />
          </div>
          <div
            className={
              "flex-auto max-h-full max-w-md lg:w-auto lg:flex-1 flex flex-col lg:mb-12 shadow-lg rounded-sm border border-slate-200"
            }
          >
            <DoughnutComponent
              title={"Verbs"}
              label={post.verbs}
              data={post.verbCount}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {isLoading && <p>Loading</p>}
      {!isLoading && <p>{showData(data)}</p>}
    </>
  );
};

export default Post;
