import {
  addDoc,
  arrayUnion,
  collection,
  doc,
  DocumentData,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";

/**
 * get post document via LinkedIn url
 * @param db
 * @param jobURL URL entered by user. i.e (www.linkedin.com/job/view/123)
 * @returns {Promise<DocumentData>} The saved post with matching LinkedIn ID
 * @returns {Promise<null>} If URL doesn't contain LinkedIn ID, not found on DB, Found but empty doc
 * https://firebase.google.com/docs/reference/node/firebase.firestore.QuerySnapshot
 */
export const getPostViaUrl = async (
  db: any,
  jobURL: string
): Promise<DocumentData | null> => {
  const id = /\d+/.exec(jobURL);
  if (!id) return null;

  const postsRef = collection(db, "posts");
  const q = query(postsRef, where("linkedInId", "==", `${id}`));

  const querySnapshot = await getDocs(q);
  if (querySnapshot && !querySnapshot.empty) {
    return querySnapshot.docs[0];
  } else {
    return null;
  }
};

/**
 * get post document by passing postId
 * @param db
 * @param postId
 */
export const getPostViaId = async (db: any, postId: string) => {
  const postRef = doc(db, "posts", postId);
  const post = await getDoc(postRef);
  return post;
};

/**
 * creates new post document in database.
 */
export const createNewPost = async (db: any, data: any) => {
  const docRef = await addDoc(collection(db, "posts"), data);
  return docRef;
};

/**
 * updates post document's users field to include new userId
 * @param db
 * @param postId
 * @param userId
 */
export const addUserIdToPost = async (
  db: any,
  postId: string,
  userId: string
) => {
  const postRef = doc(db, "posts", postId);
  await updateDoc(postRef, { users: arrayUnion(userId) });
};
