export const testData = {
  city: "San Francisco, CA",
  company: "Twitch",
  employmentType: "Full-time",
  industries:
    "IT Services and IT Consulting, Software Development, and Technology, Information and Internet",
  jobFunction: "Information Technology, Consulting, and Engineering",
  keywords: [
    {
      category: "communication",
      counts: 2,
      name: "team",
    },
    {
      category: "programming",
      counts: 1,
      name: "Golang",
    },
    {
      category: "softwareDev",
      counts: 1,
      name: "Design",
    },
    {
      category: "programming",
      counts: 1,
      name: "Go",
    },
  ],
  linkedInId: "3134166831",
  nounCount: [5, 4, 4, 3, 3, 3, 2, 2, 2, 2],
  nouns: [
    "system",
    "experience",
    "status",
    "community",
    "entertainment",
    "engineering",
    "million",
    "people",
    "project",
    "team",
  ],
  seniority: "Not Applicable",
  title: "Software Engineer",
  verbCount: [4, 2, 2, 1, 1, 1, 1, 1, 1, 1],
  verbs: [
    "build",
    "solve",
    "work",
    "come",
    "chat",
    "interact",
    "find",
    "team",
    "smash",
    "empower",
  ],
};
