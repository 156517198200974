import { useSelector } from "react-redux";
import DoughnutComponent from "./DoughnutComponent";
import React from "react";
import BarChartComponent from "./BarChartComponent";
import DashboardHeader from "./DashboardHeader";
// import DashboardNav from './DashboardNav';
import KeywordChart from "./KeywordChart";

/**
 * Results houses the two graphs
 */
const Results = () => {
  const nouns: string[] = useSelector((state: any) => state.noun.value);
  const nounCount: number[] = useSelector(
    (state: any) => state.nounCount.value
  );
  const verbs: string[] = useSelector((state: any) => state.verb.value);
  const verbCount: number[] = useSelector(
    (state: any) => state.verbCount.value
  );
  const overview: any[] = useSelector((state: any) => state.overview.value);
  const keywords: any[] = useSelector((state: any) => state.keyword.value);

  const title = overview.find(({ title }) => title);
  const company = overview.find(({ company }) => company);
  const city = overview.find(({ city }) => city);
  const employmentType = overview.find(({ employmentType }) => employmentType);
  const jobFunction = overview.find(({ jobFunction }) => jobFunction);
  const industry = overview.find(({ industry }) => industry);

  // Combine items
  const allWords = nouns.concat(verbs);
  const allWordCounts = nounCount.concat(verbCount);

  const makeObjectFromData = (words: any, nums: any) => {
    let wordArr = [];
    for (let i = 0; i < words.length; i++) {
      wordArr.push({ words: words[i], count: nums[i] });
    }
    return wordArr;
  };

  const allObjects = makeObjectFromData(allWords, allWordCounts);
  allObjects.sort((a, b) => b.count - a.count);

  const orderedWords = [];
  const orderedWordCount = [];

  for (let item of allObjects) {
    orderedWords.push(item.words);
    orderedWordCount.push(item.count);
  }

  return (
    <div className={"min-h-screen bg-slate-100"}>
      {/*<DashboardNav />*/}
      <div className={"p-8"}>
        <DashboardHeader
          title={title.title}
          company={company.company}
          city={city.city}
          employmentType={employmentType.employmentType}
          jobFunction={jobFunction.jobFunction}
          industry={industry.industry}
        />
      </div>
      {/*<WelcomeBanner title={title.title} company={company.company} city={city.city} />*/}
      <div
        className={
          "flex flex-col max-h-screen m-auto lg:flex-row max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 "
        }
      >
        <div
          className={
            "w-auto h-fit lg:w-2/3 shadow-lg rounded-sm border border-slate-200"
          }
        >
          <BarChartComponent
            title={"Top 20 Words"}
            label={orderedWords}
            data={orderedWordCount}
          />
        </div>
        <div className={"px-4"} />
        <div
          className={
            "w-auto h-fit lg:w-fit shadow-lg rounded-sm border border-slate-200"
          }
        >
          <KeywordChart keywords={keywords} />
        </div>
      </div>

      <div className={"py-4"} />

      <div
        id={"donut-container"}
        className={
          "flex flex-col lg:flex-row max-w-7xl m-auto px-2 sm:px-6 lg:px-8 justify-evenly"
        }
      >
        <div
          className={
            "flex-auto max-h-full max-w-md lg:w-auto lg:flex-1 flex flex-col  lg:mb-12 shadow-lg rounded-sm border border-slate-200"
          }
        >
          <DoughnutComponent title={"Nouns"} label={nouns} data={nounCount} />
        </div>
        <div
          className={
            "flex-auto max-h-full max-w-md lg:w-auto lg:flex-1 flex flex-col lg:mb-12 shadow-lg rounded-sm border border-slate-200"
          }
        >
          <DoughnutComponent title={"Verbs"} label={verbs} data={verbCount} />
        </div>
      </div>
    </div>
  );
};

export default Results;
