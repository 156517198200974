import { useNavigate } from "react-router-dom";
import Product from "../../../assets/images/product/dashboard-ex.png";
import BgSvg from "../../../assets/images/background/squr.svg";
import { SyntheticEvent } from "react";

const Hero = () => {
  const h1Blk = "TL;DR For Job Posts";
  const subCopy = `The smarter, faster, and easy way to apply`;

  const navigate = useNavigate();

  const handleSignUp = (e: SyntheticEvent) => {
    e.preventDefault();
    navigate("/signup");
  };

  return (
    <>
      <section
        style={{
          backgroundImage: `url(${BgSvg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        {/*<Bg />*/}
        <div className="relative items-center w-full px-5 py-12 mx-auto md:px-1 lg:px-1 max-w-7xl lg:py-24 overflow-hidden">
          <div className="flex w-full mx-auto text-left">
            <div className="relative inline-flex items-center mx-auto align-middle">
              <div className="text-center">
                <h1 className="max-w-5xl text-5xl font-bold leading-none tracking-tighter text-neutral-600 lg:text-6xl lg:max-w-7xl">
                  {h1Blk}
                  <br className="hidden lg:block" />
                </h1>
                <p className="max-w-xl mx-auto mt-8 leading-relaxed text-gray-500 text-xl lg:text-2xl">
                  {subCopy}
                </p>
                <div className="flex justify-center w-full max-w-2xl gap-2 mx-auto mt-6">
                  <div className="mt-3 rounded-lg sm:mt-0">
                    <button
                      className="px-5 py-4 text-base font-medium text-center text-white transition duration-500 ease-in-out transform bg-blue-600 lg:px-10 rounded-xl hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      onClick={handleSignUp}
                    >
                      Try Jobioto Free
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section id="intro">
            <div className="flex flex-col items-center justify-center pt-24 mx-auto rounded-lg lg:px-10 max-w-7xl">
              <img
                className="object-cover object-center w-full rounded-xl"
                alt="hero"
                src={Product}
              />
            </div>
          </section>
        </div>
      </section>
    </>
  );
};

export default Hero;
