/**
 * @param url job post
 * @returns {string} analyzed data
 * @returns {null} if error
 */
export const analyzePost = async (url: any) => {
  if (process.env.REACT_APP_PROD === "false") {
    const { testData } = require("../../dashboard/utils/testData");
    return new Promise<any>((resolve) => resolve(testData));
  }

  const header = { "Content-Type": "application/json" };
  const response = await fetch(`${process.env.REACT_APP_API}/analyze`, {
    method: "POST",
    headers: header,
    body: JSON.stringify(url),
  });
  if (!response.ok) {
    console.log(response.status, response.statusText);
    return null;
  }
  return await response.json();
};
