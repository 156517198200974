import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend, Title);

const DoughnutComponent = (props: any) => {
  const options = {
    response: true,
    plugins: {},
  };

  const data = {
    labels: props.label,
    datasets: [
      {
        label: "# of Votes",
        data: props.data,
        backgroundColor: [
          "rgb(55,48,163)",
          "rgb(99,102,241)",
          "rgb(95,165,250)",
          "rgb(56,189,248)",
          "rgb(75,222,128)",
          "rgb(226,232,240)",
        ],
        borderColor: [
          "rgba(55,48,163, 0.2)",
          "rgba(99,102,241,0.2)",
          "rgba(95,165,250, 0.2)",
          "rgb(56,189,248, 0.2)",
          "rgb(75,222,128, 0.2)",
          "rgb(226,232,240, 0.2)",
        ],
        borderWidth: 1,
        hoverOffset: 4,
      },
    ],
  };

  return (
    <div className="bg-slate-50">
      <h2 className={"text-3xl text-center font-semibold"}>{props.title}</h2>
      <div className={"px-5 pt-2 pb-2"}>
        <Doughnut className={"max-h-60"} data={data} options={options} />
      </div>
    </div>
  );
};

export default DoughnutComponent;
