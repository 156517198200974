import { Popover, Transition } from "@headlessui/react";
import Logo from "../../assets/images/logo/jobioto-logo-text-black.png";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import { Fragment } from "react";

const LPNavBar = () => {
  const navigation = [
    { name: "", href: "#/" },
    { name: "", href: "#/" },
    { name: "", href: "#/" },
    { name: "", href: "#/" },
  ];
  return (
    <>
      <div className="relative z-10 pb-8 bg-white">
        <Popover>
          <div className="relative pt-6 px-4 sm:px-6 lg:px-8">
            <nav
              className="relative flex items-center justify-between sm:h-10 "
              aria-label="Global"
            >
              <div className="flex items-center flex-grow flex-shrink-0 ">
                <div className="flex items-center justify-between w-full md:w-auto">
                  <a href="#/">
                    <span className="sr-only">Jobioto</span>
                    <img
                      alt="Jobioto"
                      className="h-8 w-auto sm:h-10"
                      src={Logo}
                    />
                  </a>
                  {/*START - Contains mobile button*/}
                  <div className="-mr-2 flex items-center md:hidden">
                    <Link
                      to={"/login"}
                      className="sm:block px-5 py-2.5 text-sm font-medium text-blue-600 bg-gray-100 rounded-md hover:text-blue-600/75 transition"
                    >
                      Log In
                    </Link>
                    <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                      <span className="sr-only">Open main menu</span>
                      <MenuIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                  {/*END - Contains mobile button*/}
                </div>
              </div>

              {/*START - Desktop Menu*/}
              <div className="hidden md:block md:ml-10 md:pr-4 md:space-x-8">
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="font-medium text-gray-500 hover:text-gray-900"
                  >
                    {item.name}
                  </a>
                ))}
                <Link
                  to={"/login"}
                  className={
                    "font-medium text-indigo-600 hover:text-indigo-500"
                  }
                >
                  Log in
                </Link>
              </div>
              {/*END - Desktop Menu*/}
            </nav>
          </div>

          {/*START - Mobile Menu*/}
          <Transition
            as={Fragment}
            enter="duration-150 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Popover.Panel
              focus
              className="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
            >
              <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div className="px-5 pt-4 flex items-center justify-between">
                  <div>
                    <img className="h-8 w-auto" src={Logo} alt="" />
                  </div>
                  <div className="-mr-2">
                    <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                      <span className="sr-only">Close main menu</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>

                <div className="px-2 pt-2 pb-3 space-y-1">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                <Link
                  to={"/login"}
                  className={
                    "hidden px-3 py-2 rounded-md text-base font-medium text-indigo-600 hover:text-indigo-500 sm:block"
                  }
                >
                  Log in
                </Link>
              </div>
            </Popover.Panel>
          </Transition>
          {/*END - Mobile Menu*/}
        </Popover>
      </div>
    </>
  );
};

export default LPNavBar;
