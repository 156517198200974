export const isUrlValid = (link: string): boolean => {
  if (!link.includes("linkedin.com/jobs")) {
    return false;
  }
  const re = new RegExp("\\d+");
  const num = `${link.match(re)}`;
  if (num && num.length === 10) {
    return true;
  }
  return false;
};
