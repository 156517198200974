import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react";
import db from "../../firebase";
import { getPostViaId, getPostViaUrl } from "../../api/posts";
import { ISavedPost, UserModel } from "../../types/user";
import { DocumentData } from "firebase/firestore";
import { getUserAuth } from "../../api/auth";
import { getUserDoc } from "../../api/users";

// https://stackoverflow.com/questions/71587312/is-it-possible-to-use-firebase-query-with-redux-toolkit-or-rtk-query-in-react
export const firebaseApi = createApi({
  baseQuery: fakeBaseQuery(),
  endpoints: (build) => ({
    /**
     * POST
     * getPostViaUrl
     * getPostViaId
     */
    getPostViaUrl: build.query<DocumentData | null, string>({
      async queryFn(jobUrl) {
        const post = await getPostViaUrl(db, jobUrl);
        if (post) {
          return { data: post };
        } else {
          return { error: post };
        }
      },
    }),
    getPostViaId: build.query({
      async queryFn(postId) {
        const postData = await getPostViaId(db, postId);
        if (postData) {
          return { data: postData };
        } else {
          return { error: "Could not find post" };
        }
      },
    }),

    /**
     * USER
     * getListOfSavedPosts
     * getUserFromDb
     */

    getListOfSavedPosts: build.query<ISavedPost[], string>({
      async queryFn(userId) {
        const userData = (await getUserDoc(db, userId)) as UserModel;
        if (userData) {
          return { data: userData.savedPosts };
        } else {
          return { error: true };
        }
      },
    }),
    getUserFromDb: build.query<UserModel, string>({
      async queryFn(userId) {
        const userData = (await getUserDoc(db, userId)) as UserModel;
        if (userData) {
          return { data: userData };
        } else {
          return { error: userData };
        }
      },
    }),

    /**
     * AUTH
     * getUserAuth
     */
    getUserAuth: build.query({
      queryFn(arg) {
        try {
          const result = getUserAuth(arg);
          return { data: result };
        } catch (e) {
          return { error: e };
        }
      },
    }),
  }),
});

export const { useGetListOfSavedPostsQuery, useGetPostViaIdQuery } =
  firebaseApi;
