import { SyntheticEvent, useState } from "react";

const CustomForm = ({ status, message, onValidate }: any) => {
  const [email, setEmail] = useState("");

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    email &&
      email.indexOf("@") > -1 &&
      onValidate({
        MERGE0: email,
      });
  };

  const FormStatus = ({ status }: { status: string }) => {
    let color = "";
    let text = "";

    if (status === "sending") {
      color = "text-blue-400";
      text = "sending...";
    } else if (status === "success") {
      color = "text-green-400";
      text = "success!";
    } else if (status === "error") {
      color = "text-red-400";
      text = message;
    }

    return (
      <div className={"absolute"}>
        <span className={`${color}`}>{text}</span>
      </div>
    );
  };

  return (
    <div className="max-w-screen-xl px-4 pt-16 pb-8 mx-auto sm:px-6 lg:px-8">
      <div className="max-w-md mx-auto">
        <strong className="block text-xl font-medium text-center text-white sm:text-3xl">
          Want to stay updated with the latest?
        </strong>

        <form className="mt-6" onSubmit={handleSubmit}>
          <div className="relative max-w-lg">
            <label className="sr-only" htmlFor="email">
              {" "}
              Email{" "}
            </label>

            <input
              className="w-full p-4 pr-16 text-sm font-medium bg-white border-none rounded-full"
              id="email"
              type="email"
              placeholder="john@doe.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required={true}
            />

            <button
              className="absolute top-1/2 right-1.5 -translate-y-1/2 rounded-full bg-blue-600 p-3 text-xs font-bold uppercase tracking-wide text-white transition hover:bg-blue-700"
              type="submit"
            >
              Subscribe
            </button>
            <FormStatus status={status} />
          </div>
        </form>
      </div>
    </div>
  );
};

export default CustomForm;
