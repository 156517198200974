// import LogoBlack from "../../../assets/images/logo/jobioto-logo-text-black.png";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import LogoWhite from "../../../assets/images/logo/jobioto-logo-text.png";
import styled from "styled-components";
import CustomForm from "./CustomForm";

const Footer = () => {
  const Logo = styled.img`
    width: 118px;
  `;

  const postUrl =
    "https://jobioto.us11.list-manage.com/subscribe/post?u=54e4751a104e2a74f9edcc483&id=50d4f55d8d";

  return (
    <>
      <footer className="bg-[#1f2937]">
        {/*START - Email*/}
        <MailchimpSubscribe
          url={postUrl}
          render={({ subscribe, status, message }) => (
            <CustomForm
              status={status}
              message={message}
              onValidate={(formData: any) => subscribe(formData)}
            />
          )}
        />

        {/*START - Bottom*/}
        <div className="max-w-screen-xl px-4 py-8 mx-auto sm:px-6 lg:px-8">
          <div className="sm:flex sm:items-center sm:justify-between">
            <div className="flex justify-center text-teal-600 sm:justify-start">
              <Logo src={LogoWhite} />
            </div>
            <p className="mt-4 text-sm text-center text-gray-500 lg:text-right lg:mt-0">
              Copyright &copy; 2022. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
