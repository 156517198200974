// Redux
import { useSelector } from "react-redux";
// import Footer from "./components/landingpage/Footer";
import LoadScreen from "./components/landingpage/LoadScreen";
import { useEffect, useState } from "react";
import { auth } from "./firebase";
import { onAuthStateChanged } from "firebase/auth";
import Home from "./components/home/Home";
import LandingPage from "./components/landingpage/LandingPage";

function App() {
  const [loggedIn, setLoggedIn] = useState<boolean | undefined>(undefined);
  const isLoading: boolean = useSelector((state: any) => state.isLoading.value);

  // now check if user is logged in via firebase
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      user ? setLoggedIn(true) : setLoggedIn(false);
    });
  }, []);

  return (
    <>
      {isLoading && <LoadScreen />}
      <div
        className={`all-items flex flex-col justify-between bg-offwhite min-h-screen`}
      >
        {loggedIn === undefined && <LoadScreen />}
        {loggedIn === false && <LandingPage />}
        {loggedIn && <Home />}
        {/*<Footer />*/}
      </div>
    </>
  );
}

export default App;
