import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import db, { auth, googleProvider } from "../firebase";
import { UserModel } from "../types/user";
import { createNewUser, isUserInDatabase } from "./users";

/**
 * Sign up new user with Email and Password
 * @param email
 * @param password
 * @param navigate
 */
export const signUpUserEmailPassword = (
  email: string,
  password: string,
  navigate: any
) => {
  createUserWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      // Signed in
      const user = userCredential.user;
      const data: UserModel = {
        name: user.displayName,
        email: user.email,
        savedPosts: [],
      };
      createNewUser(db, user.uid, data).then();
      navigate("/", { replace: true });
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      alert(`Error ${errorCode}... ${errorMessage}`);
    });
};

/**
 * Sign in User with Email and Password
 * @param email
 * @param password
 * @param navigate
 */
export const signInUserEmailPassword = (
  email: string,
  password: string,
  navigate: any
) => {
  signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      // Signed in
      // const user = userCredential.user;
      navigate("/", { replace: true });
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      alert(`Error ${errorCode}... ${errorMessage}`);
    });
};

/**
 * SignIn existing or SignUp new users with GoogleAuth.
 * If new user a new user doc will be added to db.
 * Both redirect to home.
 * @param navigate
 * https://firebase.google.com/docs/auth/web/manage-users
 */
export const signInWithGoogle = (navigate: any) => {
  signInWithPopup(auth, googleProvider)
    .then(async (res) => {
      const userExists = await isUserInDatabase(db, res.user.uid);
      if (!userExists) {
        const data: UserModel = {
          name: res.user.displayName,
          email: res.user.email,
          savedPosts: [],
        };
        createNewUser(db, res.user.uid, data).then();
      }
      navigate("/", { replace: true });
    })
    .catch((error) => alert(error.message));
};

/**
 * Signs out user then reloads page
 * @param auth
 */
export const handleSignOut = (auth: any) => {
  signOut(auth)
    .then(() => {
      window.location.reload();
    })
    .catch((err) => {
      console.log(err);
    });
};

/**
 * Gets User obj from Firebase Auth
 * Prevents errors from getting manually
 * @param auth
 */
export const getUserAuth = (auth: any) => {
  return auth.currentUser;
};
