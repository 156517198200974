import { configureStore } from "@reduxjs/toolkit";
import loadingReducer from "./loading/loadingSlice";
import nounReducer from "./results/nounSlice";
import nounCountReducer from "./results/nounCountSlice";
import verbReducer from "./results/verbSlice";
import verbCountReducer from "./results/verbCountSlice";
import overviewReducer from "./results/overviewSlice";
import keywordReducer from "./results/keywords";
import { firebaseApi } from "./firebase/firebaseSlice";

export default configureStore({
  reducer: {
    isLoading: loadingReducer,
    noun: nounReducer,
    nounCount: nounCountReducer,
    verb: verbReducer,
    verbCount: verbCountReducer,
    overview: overviewReducer,
    keyword: keywordReducer,
    [firebaseApi.reducerPath]: firebaseApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      firebaseApi.middleware
    ),
});
