import { SyntheticEvent, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getUserAuth } from "../../api/auth";
import db, { auth } from "../../firebase";
import parseFormData from "../landingpage/utils/parseFormData";
import { analyzePost } from "../landingpage/utils/analyzePost";
import { serverTimestamp } from "firebase/firestore";
import { setFalse, setTrue } from "../../redux/loading/loadingSlice";
import { isUrlValid } from "./utils/analyzePostForm-utils";
import { addUserIdToPost, createNewPost, getPostViaUrl } from "../../api/posts";
import { addSavedPost, userDocHasPost } from "../../api/users";
import { ISavedPost } from "../../types/user";
import { AnalyzedPost } from "../../types/analyzedpost";

const AnalyzePostsForm = () => {
  const [jobURL, setJobURL] = useState("");
  const [urlError, setUrlError] = useState(false);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const buttonText = "Analyze";

  /** handleDatabase
   * 1) get user
   * 2) existingPost checks if LinkedInID is in database
   *  true
   *    -> parse post
   *    -> add current user to post
   *    -> redirects to results
   *  false
   *    -> get current user
   *    -> send LinkedIn url to API
   *
   * 2) analyzePost: sends url to API
   * 3)
   * yes -> parses firebase data and redirects to results
   * no -> scrapes post, adds post to db, then does above
   */
  const handleDatabase = async () => {
    const user = await getUserAuth(auth);

    // TODO: instead check the user's `savedPosts`
    const existingPost = await getPostViaUrl(db, jobURL);

    if (existingPost) {
      // TODO: DELETE
      parseFormData(existingPost.data(), dispatch);

      const userHasPost = await userDocHasPost(db, existingPost.id, user.uid);

      if (!userHasPost) {
        const existingPostData = existingPost.data() as AnalyzedPost;

        const post: ISavedPost = {
          postId: existingPost.id,
          title: existingPostData.title,
          company: existingPostData.company,
        };
        await addSavedPost(db, post, user.uid);
        await addUserIdToPost(db, existingPost.id, user.uid);
      }

      navigate(`/posts/${existingPost.id}`, { replace: true });
    } else {
      const data = (await analyzePost({ jobURL })) as AnalyzedPost;
      if (data) {
        [data.users, data.created] = [[user.uid], serverTimestamp()];

        // TODO: DELETE
        parseFormData(data, dispatch);
        const doc = await createNewPost(db, data);

        const post: ISavedPost = {
          postId: doc.id,
          title: data.title,
          company: data.company,
        };
        await addSavedPost(db, post, user.uid);
        navigate(`/posts/${doc.id}`, { replace: true });
      }
    }

    dispatch(setFalse());
  };

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    const validUrl = isUrlValid(jobURL);
    if (validUrl) {
      // Loading Start
      dispatch(setTrue());
      handleDatabase().then(() => {
        setJobURL("");
        dispatch(setFalse());
      });
    } else {
      setUrlError(!urlError);
      // TODO: error module popup
      // alert("Please enter valid URL")
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="mt-5 sm:mt-8 sm:flex justify-center "
    >
      <div>
        <div className="mt-1 relative rounded-md shadow-sm mr-2">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            {/*<span className="text-gray-500 sm:text-sm">$</span>*/}
          </div>
          <input
            type="text"
            name="jobUrl"
            value={jobURL}
            onChange={(e) => setJobURL(e.target.value)}
            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md p-4"
            placeholder="https://"
            required
          />
        </div>
      </div>
      <div className="rounded-md shadow">
        <button className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10">
          {buttonText}
        </button>
      </div>
    </form>
  );
};

export default AnalyzePostsForm;
