import { ISavedPost } from "../../types/user";
import { useNavigate } from "react-router-dom";
import { SyntheticEvent } from "react";

const SavedPost = (post: ISavedPost) => {
  const { postId, company, title } = post;
  const navigate = useNavigate();

  const handleClick = (e: SyntheticEvent) => {
    e.preventDefault();
    navigate(`/posts/${postId}`, { replace: true });
  };

  return (
    <>
      <div
        className="relative items-center w-60 max-w-sm m-3 hover:cursor-pointer"
        onClick={handleClick}
      >
        <div className="grid grid-cols-1">
          <div className="w-full max-w-lg mx-auto my-4 bg-white shadow-xl rounded-xl">
            <div className="p-6">
              <span className="mb-8 text-sm tracking-widest text-gray-900 font-bold uppercase">
                {title}
              </span>
              <p className="mt-3 text-sm font-semibold leading-relaxed text-gray-500">
                {company}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SavedPost;
