interface IKeywordChart {
  name: string;
  counts: number;
  category: string;
}

const KeywordChart = ({ keywords }: { keywords: IKeywordChart[] }) => {
  return (
    <div className="col-span-full xl:col-span-6 bg-white shadow-lg rounded-sm border border-slate-200">
      <header className="px-5 py-4 border-b border-slate-100">
        <h2 className="font-semibold text-slate-800">Notable Keywords</h2>
      </header>
      <div className="p-3">
        {/* Table */}
        <div className="overflow-x-auto">
          <table className="table-auto w-full">
            {/* Table header */}
            <thead className="text-xs font-semibold uppercase text-slate-400 bg-slate-50">
              <tr>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">Keyword</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">Category</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">Times Mentioned</div>
                </th>
              </tr>
            </thead>
            {/* Table body */}
            <tbody className="text-sm divide-y divide-slate-100">
              {keywords.map((keyword) => {
                return (
                  <tr key={""}>
                    <td className="p-2 whitespace-nowrap">
                      <div className="flex items-center">
                        {/*<div className="w-10 h-10 shrink-0 mr-2 sm:mr-3">*/}
                        {/*  <img className="rounded-full" src={customer.image} width="40" height="40" alt={customer.name} />*/}
                        {/*</div>*/}
                        <div className="font-medium text-slate-800">
                          {keyword.name}
                        </div>
                      </div>
                    </td>
                    <td className="p-2 whitespace-nowrap">
                      <div className="text-left">{keyword.category}</div>
                    </td>
                    <td className="p-2 whitespace-nowrap">
                      <div className="text-left font-medium text-green-500">
                        {keyword.counts}
                      </div>
                    </td>
                    {/*<td className="p-2 whitespace-nowrap">*/}
                    {/*  <div className="text-lg text-center">{customer.location}</div>*/}
                    {/*</td>*/}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default KeywordChart;
